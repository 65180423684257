var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(!_vm.loading)?_c('div',{staticClass:"row form__body--card form__common--center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-6"},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom01"}},[_vm._v(" "+_vm._s(_vm.$t('pagePartner.nameCompany')))]),_c('div',{staticClass:"col-sm-12"},[_vm._v(" "+_vm._s(_vm.partnerData.name_partner)+" ")])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom02"}},[_vm._v(" "+_vm._s(_vm.$t('pagePartner.department')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.department),expression:"form.department"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.department.$error
                                            },attrs:{"id":"validationCustom02","type":"text"},domProps:{"value":(_vm.form.department)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "department", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.department.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.department.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pagePartner.department') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom03"}},[_vm._v(_vm._s(_vm.$t('pagePartner.manager'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.name.$error
                                            },attrs:{"id":"validationCustom03","type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('pagePartner.manager') })))]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: '担当者' })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom04"}},[_vm._v(_vm._s(_vm.$t('pagePartner.personCharge'))+" ")]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kana_name),expression:"form.kana_name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.kana_name.$error
                                            },attrs:{"id":"validationCustom04","type":"text"},domProps:{"value":(_vm.form.kana_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "kana_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.kana_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.kana_name.katakana)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('validateField.incorrectFormat', { field: _vm.$t('pagePartner.personCharge') })))]):_vm._e(),(!_vm.$v.form.kana_name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pagePartner.personCharge') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('pagePartner.telephoneNumber')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_number),expression:"form.phone_number"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.phone_number.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.phone_number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone_number", $event.target.value)},function($event){return _vm.inputPhone('phone_number')}]}}),(_vm.submitted && _vm.$v.form.phone_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.phone_number.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max20', { field: _vm.$t('pagePartner.telephoneNumber') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom06"}},[_vm._v(_vm._s(_vm.$t('pagePartner.cellphoneNumber')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.mobile_number),expression:"form.mobile_number"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.mobile_number.$error
                                            },attrs:{"id":"validationCustom06","type":"text"},domProps:{"value":(_vm.form.mobile_number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "mobile_number", $event.target.value)},function($event){return _vm.inputPhone('mobile_number')}]}}),(_vm.submitted && _vm.$v.form.mobile_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.mobile_number.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max20', { field: _vm.$t('pagePartner.cellphoneNumber') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom07"}},[_vm._v(_vm._s(_vm.$t('pagePartner.emailPartner'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.email.$error
                                            },attrs:{"id":"validationCustom07","autocomplete":"off","type":"text"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.email.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('pagePartner.email') })))]):_vm._e(),(!_vm.$v.form.email.maxLength)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pagePartner.email') })))])]):_c('div',[(!_vm.$v.form.email.email)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.incorrectFormat', { field: _vm.$t('pagePartner.email') })))]):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom08"}},[_vm._v(_vm._s(_vm.$t('pagePartner.password'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.password.$error
                                            },attrs:{"id":"validationCustom08","type":"password","name":"password","autocomplete":"new-password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('notificationCommon.notePass'))+" ")]),(_vm.submitted && _vm.$v.form.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.password.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('pagePartner.password') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"tokenUser"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.tokenUser')))]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.chatwork_token),expression:"form.chatwork_token"}],staticClass:"form-control",class:{
                                                        'is-invalid': (_vm.submitted && _vm.$v.form.chatwork_token.$error) || _vm.inValidToken
                                                    },attrs:{"id":"tokenUser","type":"text"},domProps:{"value":(_vm.form.chatwork_token)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "chatwork_token", $event.target.value)},_vm.handleInputChatWorkToken]}}),(_vm.inValidToken || (_vm.submitted && _vm.$v.form.chatwork_token.$error))?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.chatwork_token.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pageEmployee.tokenUser') })))]):_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('pageEmployee.tokenInvalid'))+" ")])]):_vm._e()]),_c('div',{staticClass:"ml-3"},[_c('b-button',{class:{
                                                        disabled_cursor: _vm.hasButtonCheckToken
                                                    },staticStyle:{"white-space":"nowrap"},attrs:{"disabled":_vm.hasButtonCheckToken,"variant":"success"},on:{"click":_vm.handleCallChatWork}},[_vm._v(_vm._s(_vm.$t('btn.verify')))])],1)])])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"accountId"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.accountId')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.account_id),expression:"form.account_id"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.account_id.$error,
                                                disabled_cursor: _vm.isInputAccountID
                                            },attrs:{"id":"accountId","type":"number","disabled":_vm.isInputAccountID},domProps:{"value":(_vm.form.account_id)},on:{"keypress":function($event){return _vm.handleNumber($event)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "account_id", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.account_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.account_id.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max10', { field: _vm.$t('pageEmployee.accountId') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"chatworkId"}},[_vm._v(_vm._s(_vm.$t('pageEmployee.chatworkId')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.chatwork_id),expression:"form.chatwork_id"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.chatwork_id.$error
                                            },attrs:{"id":"chatworkId","type":"text"},domProps:{"value":(_vm.form.chatwork_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "chatwork_id", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.chatwork_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.chatwork_id.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('pageEmployee.chatworkId') })))]):_vm._e()]):_vm._e()])])])])])])])]),_c('Footer',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-light mr-3 btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                                path: `/partner/view?id=${_vm.$route.query.partner_id}`
                            })}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(_vm.checkPermiss('partner.register') || _vm.checkPermiss('partner.edit'))?_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"submit","disabled":_vm.isWaitToken},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.$route.query.id ? _vm.$t('btn.save') : _vm.$t('btn.register'))+" ")]):_vm._e()])])])],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('LoadingIcon')],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }