<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { masterComputed, masterMethods, partnersMethods, checkPermission, settingPhoneMethods } from '@/state/helpers';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { regexNumber, isNumber } from '../../../utils/format';
import { showMessage } from '@/utils/messages.js';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: ' 外部パートナー担当者登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        PageHeader,
        Footer
    },
    data() {
        return {
            title: ' 外部パートナー担当者登録',
            index: 1,
            loading: false,
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '外部パートナー一覧',
                    href: '/partners'
                },
                {
                    text: this.title ? this.title : '外部パートナー担当者登録',
                    active: true
                }
            ],
            partner_id: this.$route.query.partner_id,
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                password: '',
                department: '',
                mobile_number: '',
                account_id: null,
                chatwork_id: '',
                chatwork_token: ''
            },
            chatworkTokenShow: '',
            inValidToken: false,
            chatworkTokenTemp: null,
            isWaitToken: false,
            isInputAccountID: false,
            objTempChatWork: {
                chatwork_token: '',
                account_id: null,
                chatwork_id: ''
            },
            isVerifyToken: false,
            hasButtonCheckToken: true,
            partnerData: {
                name_partner: ''
            },
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false
        };
    },
    computed: {
        ...masterComputed
    },
    validations: {
        form: {
            name: { required, maxLength: maxLength(255) },
            email: { required, email, maxLength: maxLength(255) },
            department: { maxLength: maxLength(255) },
            kana_name: {
                maxLength: maxLength(255),
                katakana() {
                    if (this.form.kana_name && this.form.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.form.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            phone_number: { maxLength: maxLength(20) },
            mobile_number: { maxLength: maxLength(20) },
            password: {
                required() {
                    // Kiểm tra queryId bắt buộc nếu tồn tại query id
                    if (!this.id && !this.form.password) {
                        return false;
                    }
                    return true; // Bỏ qua kiểm tra nếu không tồn tại query id
                },
                validate() {
                    if (this.form.password && this.form.password.length < 255) {
                        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                        if (passwordRegex.test(this.form.password)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            account_id: {
                maxLength: maxLength(10)
            },
            chatwork_id: {
                maxLength: maxLength(255)
            },
            chatwork_token: {
                maxLength: maxLength(255)
            }
        }
    },
    mounted() {
        if (this.$route.query.id && this.$route.query.partner_id) {
            let data = {
                partner_id: this.$route.query.partner_id,
                id: this.$route.query.id
            };
            this.getDetail(data);
            this.title = '外部パートナー新規編集';
            this.items[2].text = '外部パートナー新規編集';
        }
        if (this.$route.query.partner_id) {
            this.getDetailPartner(this.$route.query.partner_id);
        }
    },
    methods: {
        ...masterMethods,
        ...partnersMethods,
        ...settingPhoneMethods,

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        inputPhone(type) {
            if (type === 'phone_number') {
                this.form.phone_number = regexNumber(this.form.phone_number);
            } else if (type === 'mobile_number') {
                this.form.mobile_number = regexNumber(this.form.mobile_number);
            }
        },
        getDetailPartner(id) {
            this.loading = true;
            this.detailPartners(id).then((data) => {
                this.partnerData = {
                    name_partner: data?.name
                };

                this.loading = false;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailPartnersSupport(id).then((data) => {
                this.form = data;
                this.loading = false;
                this.form.department = data?.partner_pic_detail?.department ?? '';
                this.objTempChatWork = {
                    chatwork_token: data.chatwork_token ?? '',
                    account_id: data.account_id ?? null,
                    chatwork_id: data.chatwork_id ?? ''
                };
                if (data.chatwork_token) {
                    this.chatworkTokenShow = data.chatwork_token ?? '';
                    this.isInputAccountID = true;
                } else {
                    this.chatworkTokenShow = '';
                    this.isInputAccountID = false;
                }
            });
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            if (this.isVerifyToken) {
                showMessage(8080, this.$bvToast, this.$t('pageEmployee.verifyToken'));
            }
            this.$v.$touch();
            if (this.$v.$invalid || this.inValidToken || this.form.account_id?.length > 10 || this.isVerifyToken) return;
            this.register();
        },
        register() {
            if (this.form.name && !this.loading) {
                this.loading = true;
                let data = Object.assign({}, this.form);
                data.partner_id = this.partner_id;
                data.account_id = data?.account_id ? Number(data.account_id) : null;
                if (this.$route.query.id) {
                    if (!data.password) {
                        delete data.password;
                    }
                    if (data.chatwork_token === this.chatworkTokenShow) {
                        delete data.chatwork_token;
                    }
                    this.updatePartnersSupport(data).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '外部パートナー担当者が更新されました。');
                            this.getDetailPartner(this.$route.query.partner_id);
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('notificationCommon.exitPersonInCharge'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            this.loading = false;
                        }
                    });
                } else {
                    this.registerPartnersSupport(data).then((data) => {
                        if (data.code == 200) {
                            this.$router
                                .push({
                                    path: `/partner/support/view`,
                                    query: { id: data.data.id, partner_id: this.$route.query.partner_id }
                                })
                                .then(() => {
                                    showMessage(data.code, this.$bvToast, '外部パートナー担当者が登録されました。');
                                });
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('notificationCommon.exitPersonInCharge'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                        }
                        this.loading = false;
                    });
                }
            }
        },
        handleNumber(e) {
            isNumber(e);
        },
        handleCallChatWork() {
            if ((this.chatworkTokenTemp && this.chatworkTokenTemp === this.form.chatwork_token) || !this.form.chatwork_token) return;
            this.chatworkTokenTemp = this.form.chatwork_token;
            let chatwork_token = this.form.chatwork_token;
            this.isWaitToken = true;
            this.getInfoChatWork({ chatwork_token })
                .then((res) => {
                    this.isWaitToken = false;
                    this.isVerifyToken = false;
                    if (!res) {
                        this.inValidToken = true;
                        this.form.account_id = null;
                        this.form.chatwork_id = '';
                        return;
                    }
                    this.inValidToken = false;
                    this.form.account_id = res?.account_id ?? null;
                    this.form.chatwork_id = res?.chatwork_id ?? '';
                    this.objTempChatWork = {
                        chatwork_token: this.form.chatwork_token,
                        account_id: this.form.account_id,
                        chatwork_id: this.form.chatwork_id
                    };
                })
                .catch((error) => {
                    this.isWaitToken = false;
                    console.log('error', error);
                });
        },
        handleInputChatWorkToken() {
            if (!this.form.chatwork_token) {
                this.inValidToken = false;
                this.isInputAccountID = false;
                this.isVerifyToken = false;
                this.hasButtonCheckToken = true;
                this.form.account_id = null;
                this.form.chatwork_id = '';
            } else {
                this.isInputAccountID = true;
                this.hasButtonCheckToken = false;
                if (this.form.chatwork_token !== this.chatworkTokenTemp) {
                    this.form.account_id = null;
                    this.form.chatwork_id = '';
                    this.isVerifyToken = true;
                } else {
                    this.form.account_id = this.objTempChatWork.account_id;
                    this.form.chatwork_id = this.objTempChatWork.chatwork_id;
                    this.isVerifyToken = false;
                }
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-6">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom01"> {{ $t('pagePartner.nameCompany') }}</label>
                                        <div class="col-sm-12">
                                            {{ partnerData.name_partner }}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom02"> {{ $t('pagePartner.department') }}</label>

                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom02"
                                                v-model="form.department"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.department.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.department.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.department.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('pagePartner.department')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom03"
                                            >{{ $t('pagePartner.manager') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom03"
                                                v-model="form.name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.name.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.name.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('pagePartner.manager')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.name.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: '担当者'
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom04">{{ $t('pagePartner.personCharge') }} </label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom04"
                                                v-model="form.kana_name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.kana_name.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.kana_name.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.kana_name.katakana">
                                                    {{
                                                        $t('validateField.incorrectFormat', {
                                                            field: $t('pagePartner.personCharge')
                                                        })
                                                    }}</span
                                                >
                                                <span v-if="!$v.form.kana_name.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('pagePartner.personCharge')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('pagePartner.telephoneNumber') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.phone_number"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.phone_number.$error
                                                }"
                                                @input="inputPhone('phone_number')"
                                            />
                                            <div v-if="submitted && $v.form.phone_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.phone_number.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: $t('pagePartner.telephoneNumber')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom06">{{ $t('pagePartner.cellphoneNumber') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom06"
                                                v-model="form.mobile_number"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.mobile_number.$error
                                                }"
                                                @input="inputPhone('mobile_number')"
                                            />
                                            <div v-if="submitted && $v.form.mobile_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.mobile_number.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: $t('pagePartner.cellphoneNumber')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom07"
                                            >{{ $t('pagePartner.emailPartner') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom07"
                                                v-model="form.email"
                                                autocomplete="off"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.email.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.email.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('pagePartner.email')
                                                    })
                                                }}</span>
                                                <div v-if="!$v.form.email.maxLength">
                                                    <span>{{
                                                        $t('validateField.max255', {
                                                            field: $t('pagePartner.email')
                                                        })
                                                    }}</span>
                                                </div>
                                                <div v-else>
                                                    <span v-if="!$v.form.email.email">{{
                                                        $t('validateField.incorrectFormat', {
                                                            field: $t('pagePartner.email')
                                                        })
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom08"
                                            >{{ $t('pagePartner.password') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <input
                                                id="validationCustom08"
                                                v-model="form.password"
                                                type="password"
                                                name="password"
                                                autocomplete="new-password"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.password.$error
                                                }"
                                            />
                                            <span>
                                                {{ $t('notificationCommon.notePass') }}
                                            </span>
                                            <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.password.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('pagePartner.password')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="tokenUser">{{ $t('pageEmployee.tokenUser') }}</label>
                                        <div class="col-sm-12">
                                            <div class="d-flex">
                                                <div class="w-100">
                                                    <input
                                                        id="tokenUser"
                                                        v-model="form.chatwork_token"
                                                        type="text"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': (submitted && $v.form.chatwork_token.$error) || inValidToken
                                                        }"
                                                        @input="handleInputChatWorkToken"
                                                    />
                                                    <div v-if="inValidToken || (submitted && $v.form.chatwork_token.$error)" class="invalid-feedback">
                                                        <span v-if="!$v.form.chatwork_token.maxLength">{{
                                                            $t('validateField.max255', {
                                                                field: $t('pageEmployee.tokenUser')
                                                            })
                                                        }}</span>
                                                        <span v-else class="text-danger"> {{ $t('pageEmployee.tokenInvalid') }} </span>
                                                    </div>
                                                </div>
                                                <div class="ml-3">
                                                    <b-button
                                                        :disabled="hasButtonCheckToken"
                                                        variant="success"
                                                        @click="handleCallChatWork"
                                                        :class="{
                                                            disabled_cursor: hasButtonCheckToken
                                                        }"
                                                        style="white-space: nowrap"
                                                        >{{ $t('btn.verify') }}</b-button
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="accountId">{{ $t('pageEmployee.accountId') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="accountId"
                                                v-model="form.account_id"
                                                type="number"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.account_id.$error,
                                                    disabled_cursor: isInputAccountID
                                                }"
                                                :disabled="isInputAccountID"
                                                @keypress="handleNumber($event)"
                                            />
                                            <div v-if="submitted && $v.form.account_id.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.account_id.maxLength">{{
                                                    $t('validateField.max10', {
                                                        field: $t('pageEmployee.accountId')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="chatworkId">{{ $t('pageEmployee.chatworkId') }}</label>
                                        <div class="col-sm-12">
                                            <input
                                                id="chatworkId"
                                                v-model="form.chatwork_id"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.chatwork_id.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.chatwork_id.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.chatwork_id.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('pageEmployee.chatworkId')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light mr-3 btn-sm"
                            @click="
                                $router.push({
                                    path: `/partner/view?id=${$route.query.partner_id}`
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary ml-3"
                            v-if="checkPermiss('partner.register') || checkPermiss('partner.edit')"
                            :disabled="isWaitToken"
                            @click="formSubmit"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style scoped>
.btn__get--token {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    padding-top: 5px;
    padding-inline: 10px;
    background-color: transparent;
}
.btn__get--token--icon {
    font-size: 20px;
    color: #858585;
    opacity: 0.9;
}
.btn__get--token--icon:hover {
    opacity: 1;
}
</style>
