<script>
import { required, email } from 'vuelidate/lib/validators';

import { authMethods, authFackMethods, notificationMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

export default {
    data() {
        return {
            email: '',
            password: '',
            submitted: false,
            iconEye: 'fa fa-eye-slash'
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        document.body.classList.add('auth-body-bg');
    },
    validations: {
        email: { required, email },
        password: {
            required,
            validate() {
                if (this.password && this.password.length < 255) {
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                    if (passwordRegex.test(this.password)) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            }
        }
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
                    this.tryingToLogIn = true;
                    // Reset the authError if it existed.
                    this.authError = null;
                    return (
                        this.logIn({
                            email: this.email,
                            password: this.password
                        })
                            // eslint-disable-next-line no-unused-vars
                            .then((token) => {
                                this.tryingToLogIn = false;
                                this.isAuthError = false;
                                // Redirect to the originally requested page, or to the home page
                                this.$router.push(this.$route.query.redirectFrom || { name: 'home' });
                            })
                            .catch((error) => {
                                this.tryingToLogIn = false;
                                this.authError = error ? error : '';
                                this.isAuthError = true;
                            })
                    );
                } else {
                    const { email, password } = this;
                    if (email && password) {
                        this.login({ email, password }).then((data) => {
                            if (data.code == 200) {
                                const redirectUrl = localStorage.getItem('redirectAfterLogin');
                                if (redirectUrl) {
                                    localStorage.removeItem('redirectAfterLogin');
                                    window.location.href = redirectUrl;
                                } else {
                                    this.$router.push({ path: '/' }).then(() => {
                                        showMessage(data.code, this.$bvToast, 'ログインが完了しました。');
                                    });
                                }
                            }
                            if (data.code == 401) {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            if (data.code == 422) {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                        });
                    }
                }
            }
        },
        handleTriggerPass() {
            let pass = document.getElementById('userpassword');
            if (pass.type == 'password') {
                pass.type = 'text';
                this.iconEye = 'fa fa-eye';
            } else {
                pass.type = 'password';
                this.iconEye = 'fa fa-eye-slash';
            }
        }
    }
};
</script>

<template>
    <div>
        <div>
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div class="w-100">
                                <div class="row justify-content-center">
                                    <div class="col-lg-4">
                                        <div>
                                            <div class="text-center">
                                                <h4 class="font-size-18 mt-4">ログイン</h4>
                                            </div>

                                            <div class="p-2 mt-5">
                                                <form class="form-horizontal" @submit.prevent="tryToLogIn">
                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-mail-line auti-custom-input-icon"></i>
                                                        <label for="email"
                                                            >ID
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            v-model="email"
                                                            class="form-control"
                                                            id="email"
                                                            placeholder="ID"
                                                            :class="{ 'is-invalid': submitted && $v.email.$error }"
                                                        />
                                                        <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                                            <span v-if="!$v.email.required">メールアドレスを入力してください。</span>
                                                            <span v-if="!$v.email.email">メールアドレスの形式は正しくありません。</span>
                                                        </div>
                                                    </div>

                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <label for="userpassword"
                                                            >パスワード
                                                            <span class="text-danger">*</span>
                                                        </label>
                                                        <div
                                                            class="inputBox"
                                                            :class="{
                                                                'is-invalid': submitted && $v.password.$error
                                                            }"
                                                        >
                                                            <input
                                                                v-model="password"
                                                                type="password"
                                                                class="form-control"
                                                                id="userpassword"
                                                                placeholder="パスワード入力"
                                                            />
                                                            <span @click="handleTriggerPass">
                                                                <i :class="iconEye" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                        <div v-if="submitted && $v.password.$error" class="invalid-feedback">
                                                            <span v-if="!$v.password.required"> パスワードを入力してください。</span>
                                                            <span v-if="!$v.password.validate"
                                                                >パスワードは大文字、小文字、数字、特殊文字を含む8～32文字以内で入力してください。</span
                                                            >
                                                        </div>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        <button class="btn btn-primary w-md waves-effect waves-light" type="submit">ログイン</button>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        <router-link tag="a" to="/forgot-password" class="text-muted">
                                                            <i class="mdi mdi-lock mr-1"></i> *パスワードを忘れた方
                                                        </router-link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
.inputBox {
    position: relative;
    & input {
        padding-right: 26px;
    }
    & span {
        position: absolute;
        right: 5px;
        top: 10px;
        cursor: pointer;
        &:hover {
            color: #505d69cf;
        }
    }
}
</style>
